@import url("./icon.css");
@import url("./btnstyles.css");

body {
    font-family: "微軟正黑體";
}

*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-track {
    background-color: lightgray;
}

*::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 20px;
}

.pointer {
    cursor: pointer;
}

.text-danger {
    color: #a10506;
}

.text-success {
    color: #3b9217;
}

.text-primary {
    color: #2c6ab5;
}

.modal-header {
    background-color: #004aad;
    color: white;
    font-weight: bolder;
}

.btn-close {
    color: white !important;
    opacity: 1 !important;
    font-weight: bolder !important;
}

.btn-close:hover {
    color: white;
    opacity: 1;
    font-weight: bolder;
}

.icon-link {
    font-size: 32px;
    text-decoration: none;
}

.thead-dark-red {
    background-color: #90514a;
    color: white;
}

.thead-dark-red>tr>th {
    background-color: #90514a;
    color: white;
}

.thead-dark-blue {
    background-color: #2c6ab5;
    color: white;
}

.card-header {
    background-color: #004aad;
    color: white;
    font-weight: bolder;
}

.row-selected-bg {
    background-color: rgb(234, 225, 43) !important;
}

.row-selected-bg>td {
    background-color: rgb(234, 225, 43);
}

.input-invalid {
    border-color: 1px red solid;
}

.form-control-input {
    height: 50%;
    padding: 0;
    text-align: center;
    width: 100px;
    margin: auto;
}

.toast-center {
    position: absolute;
    top: calc(15% - 27px);
    left: calc(50% - 150px);
}

.date-picker {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.date-picker::before {
    background: url("./assets/icon/calendar-icon-blue.svg") no-repeat center;
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5px;
    transform: translateX(550%);
}

.ng-invalid:not(form, .search-input) {
    border: 1px solid red;
}
