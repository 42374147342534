@font-face {
  font-family: "icomoon";
  src: url("./assets/icon/fonts/icomoon.eot?f2cwol");
  src: url("./assets/icon/fonts/icomoon.eot?f2cwol#iefix")
      format("embedded-opentype"),
    url("./assets/icon/fonts/icomoon.ttf?f2cwol") format("truetype"),
    url("./assets/icon/fonts/icomoon.woff?f2cwol") format("woff"),
    url("./assets/icon/fonts/icomoon.svg?f2cwol#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Add:before {
    content: "\e900";
    color: #2c6ab5;
  }
  .icon-Application:before {
    content: "\e901";
    color: #24519a;
  }
  .icon-Application-for-exemption-from-disadvantaged-bursaries:before {
    content: "\e902";
    color: #2b65a9;
  }
  .icon-Apply-scholarships:before {
    content: "\e903";
    color: #2c6ab5;
  }
  .icon-Auth:before {
    content: "\e904";
    color: #24519a;
  }
  .icon-Back-to-school-application:before {
    content: "\e905";
    color: #2b65a9;
  }
  .icon-Barcode-button:before {
    content: "\e906";
    color: #2c6ab5;
  }
  .icon-Camera:before {
    content: "\e907";
  }
  .icon-Change-password:before {
    content: "\e908";
    color: #2c6ab5;
  }
  .icon-Class-schedule:before {
    content: "\e909";
    color: #2c6ab5;
  }
  .icon-Classroom-schedule:before {
    content: "\e90a";
    color: #2c6ab5;
  }
  .icon-Clear-button:before {
    content: "\e90b";
    color: #2c6ab5;
  }
  .icon-Clock:before {
    content: "\e90c";
  }
  .icon-Copy-button:before {
    content: "\e90d";
    color: #2c6ab5;
  }
  .icon-Course-selection:before {
    content: "\e90e";
    color: #2c69b5;
  }
  .icon-Cross-department:before {
    content: "\e90f";
    color: #2c6ab5;
  }
  .icon-Cross-department-review:before {
    content: "\e910";
    color: #2c6ab5;
  }
  .icon-Current-position .path1:before {
    content: "\e911";
    color: rgb(161, 5, 6);
  }
  .icon-Current-position .path2:before {
    content: "\e912";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Current-position .path3:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(161, 5, 6);
  }
  .icon-Current-position .path4:before {
    content: "\e914";
    margin-left: -1em;
    color: rgb(161, 5, 6);
  }
  .icon-Current-position .path5:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(161, 5, 6);
  }
  .icon-Discussion-forum:before {
    content: "\e916";
    color: #2c6ab5;
  }
  .icon-Distance-teaching:before {
    content: "\e917";
    color: #2c6ab5;
  }
  .icon-Down-arrow:before {
    content: "\e918";
  }
  .icon-Downlad-button:before {
    content: "\e919";
    color: #2c6ab5;
  }
  .icon-Download-payment-slip-button:before {
    content: "\e91a";
    color: #2c6ab5;
  }
  .icon-Download-proof-of-payment-button:before {
    content: "\e91b";
    color: #2c6ab5;
  }
  .icon-Drop-out-of-school-application:before {
    content: "\e91c";
    color: #2b65a9;
  }
  .icon-Export-button:before {
    content: "\e91d";
    color: #2c6ab5;
  }
  .icon-Export-excel-button:before {
    content: "\e91e";
    color: #2c6ab5;
  }
  .icon-Failed-button:before {
    content: "\e91f";
    color: #a10506;
  }
  .icon-Fill-in-button:before {
    content: "\e920";
    color: #2c6ab5;
  }
  .icon-Final-teaching-assessment:before {
    content: "\e921";
    color: #2c6ab5;
  }
  .icon-Graduation-threshold:before {
    content: "\e922";
    color: #2b65a9;
  }
  .icon-Group-list-maintenance:before {
    content: "\e923";
    color: #2c6ab5;
  }
  .icon-Help:before {
    content: "\e924";
    color: #fff;
  }
  .icon-Historical-score:before {
    content: "\e925";
    color: #2c6ab5;
  }
  .icon-Home:before {
    content: "\e926";
  }
  .icon-Homework-correction:before {
    content: "\e927";
    color: #2c6ab5;
  }
  .icon-Homework-maintenance:before {
    content: "\e928";
    color: #2c6ab5;
  }
  .icon-Homwork-upload:before {
    content: "\e929";
    color: #2c6ab5;
  }
  .icon-Import-button:before {
    content: "\e92a";
    color: #2c6ab5;
  }
  .icon-Left-arrow:before {
    content: "\e92b";
  }
  .icon-List-of-students-taking-courses:before {
    content: "\e92c";
    color: #2c6ab5;
  }
  .icon-Location:before {
    content: "\e92d";
  }
  .icon-LOG-record:before {
    content: "\e92e";
    color: #2c6ab5;
  }
  .icon-Logout:before {
    content: "\e92f";
    color: white;
  }
  .icon-Map:before {
    content: "\e930";
    color: #2c6ab5;
  }
  .icon-Message-notification:before {
    content: "\e931";
    color: #2c6ab5;
  }
  .icon-Microphone:before {
    content: "\e932";
    color: #2c6ab5;
  }
  .icon-Mid-term-teaching-assessment:before {
    content: "\e933";
    color: #2c6ab5;
  }
  .icon-Mid-term-warning:before {
    content: "\e934";
    color: #2b65a9;
  }
  .icon-Military-service:before {
    content: "\e935";
    color: #2c6ab5;
  }
  .icon-Minus-button:before {
    content: "\e936";
    color: #a10506;
  }
  .icon-Modify-button:before {
    content: "\e937";
    color: #2c6ab5;
  }
  .icon-My-favorite:before {
    content: "\e938";
  }
  .icon-My-schedule:before {
    content: "\e939";
    color: #2c6ab5;
  }
  .icon-Office-Hour-application:before {
    content: "\e93a";
    color: #2c6ab5;
  }
  .icon-Office-Hour-record:before {
    content: "\e93b";
    color: #2c6ab5;
  }
  .icon-Office-Hour-reply:before {
    content: "\e93c";
    color: #2c6ab5;
  }
  .icon-Office-Hour-setting:before {
    content: "\e93d";
    color: #2c6ab5;
  }
  .icon-Online-leave:before {
    content: "\e93e";
    color: #2c6ab5;
  }
  .icon-Online-rewards-and-punishments-maintenance-suggestions:before {
    content: "\e93f";
    color: #2c6ab5;
  }
  .icon-Online-roll-call:before {
    content: "\e940";
    color: #2c6ab5;
  }
  .icon-Online-roll-call-2:before {
    content: "\e941";
    color: #2b65a9;
  }
  .icon-Other-app:before {
    content: "\e942";
    color: #24519a;
  }
  .icon-Passed-button:before {
    content: "\e943";
    color: #368f11;
  }
  .icon-Payment-download:before {
    content: "\e944";
    color: #2c6ab5;
  }
  .icon-Permanent-file-maintenance:before {
    content: "\e945";
    color: #2c6ab5;
  }
  .icon-Personal-basic-information-maintenance:before {
    content: "\e946";
    color: #2c6ab5;
  }
  .icon-Personal-leave-absence-record:before {
    content: "\e947";
    color: #2c6ab5;
  }
  .icon-Personal-reward-and-punishment-record:before {
    content: "\e948";
    color: #2c6ab5;
  }
  .icon-Play-button:before {
    content: "\e949";
    color: #2c6ab5;
  }
  .icon-Plus-button:before {
    content: "\e94a";
    color: #2c6ab5;
  }
  .icon-Print-button:before {
    content: "\e94b";
    color: #2c69b5;
  }
  .icon-Professional-core-competence:before {
    content: "\e94c";
    color: #2c6ab5;
  }
  .icon-Profile:before {
    content: "\e94d";
  }
  .icon-Profile-2:before {
    content: "\e94e";
    color: #24519a;
  }
  .icon-Quit-school-application:before {
    content: "\e94f";
    color: #2b65a9;
  }
  .icon-Quiz-correction:before {
    content: "\e950";
    color: #2c6ab5;
  }
  .icon-Quiz-maintenance:before {
    content: "\e951";
    color: #2c6ab5;
  }
  .icon-Quiz-upload:before {
    content: "\e952";
    color: #2c6ab5;
  }
  .icon-Result-of-registration-for-the-first-stage:before {
    content: "\e953";
    color: #2c6ab5;
  }
  .icon-Result-of-registration-for-the-first-stage1:before {
    content: "\e954";
    color: #2b65a9;
  }
  .icon-Right-arrow:before {
    content: "\e955";
  }
  .icon-Save-Button:before {
    content: "\e956";
    color: #2c6ab5;
  }
  .icon-Scholarship-record:before {
    content: "\e957";
    color: #2c6ab5;
  }
  .icon-School-loan-application:before {
    content: "\e958";
    color: #2c6ab5;
  }
  .icon-School-level-core-competence:before {
    content: "\e959";
    color: #2c6ab5;
  }
  .icon-Schoolwide-course-information:before {
    content: "\e95a";
    color: #2c6ab5;
  }
  .icon-Score-registration-and-import:before {
    content: "\e95b";
    color: #2c6ab5;
  }
  .icon-Semester-results-query:before {
    content: "\e95c";
    color: #2c6ab5;
  }
  .icon-Std-cos-maintenance:before {
    content: "\e95d";
    color: #2b65a9;
  }
  .icon-Std-cos-purpose:before {
    content: "\e95e";
    color: #2b65a9;
  }
  .icon-Student-basic-information-query:before {
    content: "\e95f";
    color: #2c6ab5;
  }
  .icon-Student-leave-review:before {
    content: "\e960";
    color: #2c6ab5;
  }
  .icon-Student-learning-status:before {
    content: "\e961";
    color: #2c6ab5;
  }
  .icon-Syllabus-input:before {
    content: "\e962";
    color: #2c6ab5;
  }
  .icon-Teacher-schedule-query:before {
    content: "\e963";
    color: #2c6ab5;
  }
  .icon-Teachers-hourly-fee-details-query:before {
    content: "\e964";
    color: #2c6ab5;
  }
  .icon-Teaching-Evaluation-Query:before {
    content: "\e965";
    color: #2c6ab5;
  }
  .icon-Textbook-download:before {
    content: "\e966";
    color: #2c6ab5;
  }
  .icon-Textbook-maintenance:before {
    content: "\e967";
    color: #2c6ab5;
  }
  .icon-Tuition-fee-reduction-application:before {
    content: "\e968";
    color: #2b65a9;
  }
  .icon-Up-arrow:before {
    content: "\e969";
  }
  .icon-Upload-button:before {
    content: "\e96a";
    color: #2c6ab5;
  }
  .icon-Verify-button:before {
    content: "\e96b";
    color: #2c6ab5;
  }
  .icon-View-button:before {
    content: "\e96c";
    color: #2c6ab5;
  }
  .icon-Warning-record:before {
    content: "\e96d";
    color: #2c6ab5;
  }
