.search-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

.search-btn:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
    transition: 0.3s;
}

.search-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/search-icon-blue.svg") no-repeat center;
    transition: 0.3s;
}

/*預設按鈕_滑動*/
.search-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
    transition: 0.3s;
}

/* 前面黃色梯形_滑動 */
.search-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*放大鏡_滑動*/
.search-btn:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/search-icon-yellow.svg") no-repeat center;
}

/*其他按鈕 藍色 hover灰底*/
.other-Button-gray {
    width: 100px;
    height: 38px;
    font-family: "微軟正黑體";
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    background-color: hsl(213, 61%, 44%);
    border: 2px solid #2c6ab5;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    padding: 0px 10px;
    position: relative;
}

.other-Button-gray:hover {
    width: 100px;
    height: 38px;
    font-family: "微軟正黑體";
    font-size: 16px;
    font-weight: normal;
    color: #2c6ab5;
    background-color: #ffffffb6;
    border: 2px solid #2c6ab5;
    border-radius: 20px;
    cursor: pointer;
    padding: 0px 10px;
    position: relative;
}

/*其他按鈕 藍色 hover白底*/
.other-Button-blue {
    width: 100px;
    height: 38px;
    font-family: "微軟正黑體";
    font-size: 16px;
    font-weight: normal;
    color: #2c6ab5;
    background-color: #FFFFFF;
    border: 2px solid #2c6ab5;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    padding: 0px 10px;
    position: relative;
}

.other-Button-blue:hover {
    width: 100px;
    height: 38px;
    font-family: "微軟正黑體";
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    background-color: #2c6ab5;
    border: 2px solid #2c6ab5;
    border-radius: 20px;
    cursor: pointer;
    padding: 0px 10px;
    position: relative;
}

.import-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

.import-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/import-icon-blue.svg") no-repeat center;
    transition: 0.3s;
}

/*匯入按鈕_滑動*/
.import-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
    transition: 0.3s;
}

/*前面黃色梯形_滑動*/
.import-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*圖案_滑動*/
.import-btn:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/import-icon-yellow.svg") no-repeat center;
}

/*儲存無線按鈕(C#) 灰色*/
.save-btn-gray {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.save-btn-gray:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*圖案*/
.save-btn-gray:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/save-icon-blue.svg") no-repeat center;
}

/*儲存按鈕_滑動*/
.save-btn-gray:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形_滑動*/
.save-btn-gray:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*圖案_滑動*/
.save-btn-gray:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/save-icon-yellow.svg") no-repeat center;
}

/* 修改按鈕 黃色 */
.modify-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.modify-btn:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*修改圖示*/
.modify-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/modify-icon-blue.svg") no-repeat center;
}

/*預設按鈕_滑動*/
.modify-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}


/*前面黃色梯形_滑動*/
.modify-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*修改圖示_滑動*/
.modify-btn:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/modify-icon-yellow.svg") no-repeat center;
}

/*修改按鈕(白色背景) 黃色*/
.modify-btn-white {
    width: 100px;
    height: 38px;
    background: white;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.modify-btn-white:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #ffffff transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 26px 20px 0px 10px;
}

/*修改(白色背景)圖示*/
.modify-btn-white:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/modify-icon-blue.svg") no-repeat center;
}

/*預設按鈕_滑動*/
.modify-btn-white:hover {
    width: 100px;
    height: 38px;
    background: white;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}


/*前面黃色梯形_滑動*/
.modify-btn-white:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #ffffff transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*修改(白色背景)圖示_滑動*/
.modify-btn-white:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/modify-icon-yellow.svg") no-repeat center;
}

/*新增按鈕 黃色*/
.add-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.add-btn:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*新增圖示*/
.add-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/add-icon-blue.svg") no-repeat center;
}

/*預設按鈕_滑動*/
.add-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}


/*前面黃色梯形_滑動*/
.add-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
    transition: 0.3s;
}

/*新增圖示_滑動*/
.add-btn:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/add-icon-yellow.svg") no-repeat center;
}

/*新增按鈕 黃色  白底*/
.add-btn-white {
    width: 100px;
    height: 38px;
    background: #ffffff;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.add-btn-white:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #ffffff transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*新增圖示*/
.add-btn-white:after {
    content: "";
    display: block;
    position: absolute;
    left: 4px;
    top: 2px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/add-icon-blue.svg") no-repeat center;
}

/*預設按鈕_滑動*/
.add-btn-white:hover {
    width: 100px;
    height: 38px;
    background: #ffffff;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding: 0px 10px;
    box-sizing: border-box;
}


/*前面黃色梯形_滑動*/
.add-btn-white:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #ffffff transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*新增圖示_滑動*/
.add-btn-white:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/add-icon-yellow.svg") no-repeat center;
}

/*送出按鈕 黃色*/
.send-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.send-btn:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*送出圖示*/
.send-btn:after {
    content: "";

    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/send-icon-blue.svg") no-repeat center;
}

/*預設按鈕_滑動*/
.send-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;

    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}


/*前面黃色梯形_滑動*/
.send-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;

    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*送出圖示_滑動*/
.send-btn:hover::after {
    content: "";

    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/send-icon-yellow.svg") no-repeat center;
}

/*匯出按鈕 黃色*/
.export-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

/*前面黃色梯形*/
.export-btn:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*匯出圖示*/
.export-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/export-icon-blue.svg") no-repeat center;
}

/*預設按鈕_滑動*/
.export-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}


/*前面黃色梯形_滑動*/
.export-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*匯出圖示_滑動*/
.export-btn:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 5px;
    width: 25px;
    height: 25px;
    background: url("./assets/icon/export-icon-yellow.svg") no-repeat center;
}

.upload-btn {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #f6c555;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
}

.upload-btn:before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #f6c555 #fafafa transparent #f6c555;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
    transition: 0.3s;
}

.upload-btn:after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 3px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/upload-icon-blue.svg") no-repeat center;
    transition: 0.3s;
}

/*預設按鈕_滑動*/
.upload-btn:hover {
    width: 100px;
    height: 38px;
    background: #fafafa;
    border: 2px solid #2c69b5;
    border-radius: 20px;
    position: relative;
    color: #2c69b5;
    font-family: "微軟正黑體";
    font-size: 16px;
    text-indent: 4px;
    cursor: pointer;
    display: block;
    padding-left: 20px;
    padding-top: 2px;
    box-sizing: border-box;
    transition: 0.3s;
}

/* 前面黃色梯形_滑動 */
.upload-btn:hover::before {
    content: "";
    width: 40px;
    height: 0px;
    display: block;
    position: absolute;
    left: -1px;
    top: 0px;
    border-radius: 20px 0 0 20px;
    border-color: #2c69b5 #fafafa transparent #2c69b5;
    border-style: solid solid solid solid;
    border-width: 34px 20px 0px 10px;
}

/*放大鏡_滑動*/
.upload-btn:hover::after {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 3px;
    width: 25px;
    height: 25px;
    background: url("../src/assets/icon/upload-icon-yellow.svg") no-repeat center;
}

/*其他按鈕 藍色 hover灰底*/
.other-Button-gray_SS {
    width: 55px;
    height: 38px;
    font-family: "微軟正黑體";
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    background-color: hsl(213, 61%, 44%);
    border: 2px solid #2c6ab5;
    border-radius: 18px;
    cursor: pointer;
    transition: 0.3s;
    padding: 0px 10px;
    position: relative;
}

.other-Button-gray_SS:hover {
    width: 55px;
    height: 38px;
    font-family: "微軟正黑體";
    font-size: 14px;
    font-weight: normal;
    color: #2c6ab5;
    background-color: #ffffffb6;
    border: 2px solid #2c6ab5;
    border-radius: 18px;
    cursor: pointer;
    padding: 0px 10px;
    position: relative;
}
